<template>
  <AppBody class="!mt-0 pt-8 pb-0">
    <div class="mb-10">
      <img
        :src="Logo"
        alt="Logo"
        class="w-64 mx-auto"
      >
    </div>

    <Swiper
      :modules="modules"
      :slides-per-view="1"
      :pagination="{clickable: true}"
    >
      <SwiperSlide>
        <SliderItem
          title="Prepare my case"
          :slide-image-src="PrepareMyCase"
        >
          Our app provides tools and resources to help you organize all the necessary details and build a strong defense efficiently.
        </SliderItem>
      </SwiperSlide>
      <SwiperSlide>
        <SliderItem
          title="Explain my rights"
          :slide-image-src="ExplainMyRights"
        >
          Our app simplifies the process of understanding your legal rights, enabling you to navigate the law and make informed decisions with ease.
        </SliderItem>
      </SwiperSlide>
      <SwiperSlide>
        <SliderItem
          title="Do I have a case?"
          :slide-image-src="DoIHaveACase"
        >
          Wondering "Do I have a case?" Our app can help. Let's start exploring your legal options together!
        </SliderItem>
      </SwiperSlide>
      <SwiperSlide>
        <SliderItem
          title="Help me with my CCMA documents"
          :slide-image-src="CCMADocuments"
        >
          We're here to help you navigate and prepare CCMA-related documents, guiding you towards a smooth legal resolution.
        </SliderItem>
      </SwiperSlide>
    </Swiper>

    <template v-if="store.isAuthenticated">
      <div class="flex justify-center">
        <DefaultButton
          :to="{ name: 'app_matter_list' }"
        >
          Start Chatting
        </DefaultButton>
      </div>
    </template>
    <template v-else>
      <div class="flex space-x-px  mb-4 justify-center w-full">
        <DefaultButton
          :to="{ name: 'app_security_sign_up' }"
          border-radius-class="rounded-l-md"
        >
          Sign up
        </DefaultButton>
        <DefaultButton
          :to="{ name: 'app_security_login' }"
          border-radius-class="rounded-r-md !w-full"
        >
          Log in
        </DefaultButton>
      </div>

      <div class="flex flex-col w-full items-center">
        <DefaultButton
          :to="{ name: 'app_security_anonymous_chat' }"
          button-colour-class="btn-primary-light-blue"
        >
          Anonymous chat
        </DefaultButton>

        <RouterLink
          :to="{ name: 'app_security_forgot_password' }"
          class="btn btn-link !underline underline-offset-2"
        >
          Forgot password
        </RouterLink>
      </div>
    </template>
  </AppBody>
</template>

<script setup>

import { AppBody } from '@/components';
import { DefaultButton } from '@/components/buttons';
import { SliderItem } from '@/components/interactivity';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const modules = [Pagination];

import { Logo, CCMADocuments, PrepareMyCase, DoIHaveACase, ExplainMyRights } from '@/assets/images';
import { useSecurityStore } from '@/store';

// Composables
const store = useSecurityStore();

</script>

<style>
.swiper-pagination-fraction,
.swiper-pagination-custom, .swiper-horizontal,
.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  @apply relative bottom-4;
}

.swiper-pagination-bullet {
  @apply w-6 h-1 rounded-sm;
}

.swiper-pagination {
  @apply bottom-10;
}

.swiper-pagination-bullet-active {
  @apply bg-secondary;
}
</style>

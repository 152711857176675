<template>
  <ul>
    <li
      v-for="category in categories"
      :key="category.id"
      class=""
    >
      <RouterLink
        :to="{ name: 'app_matter_new_select_category_form', params: { categoryId: category.id } }"
        class="no-underline"
      >
        <div class="flex items-center border-b pt-2.5 pb-3 w-full">
          <div
            class="relative rounded-full  flex justify-center items-center"
          >
            <i
              class="fa-duotone fa-book-blank text-primary mt-[3px] text-3xl"
              :style="getCategoryColorClass(category.id).text"
            />
            <span
              class="text-sm font-semibold text-white absolute  top-1.5 left-1.5"
              :style="getCategoryColorClass(category.id).bubble"
            >
              {{ getCategoryInitials(category.name) }}
            </span>
          </div>

          <!--          <div-->
          <!--            class="relative w-9 h-9 rounded-full bg-primary-light flex justify-center items-center"-->
          <!--            :style="getCategoryColorClass(category.id).background"-->
          <!--          >-->
          <!--            <span-->
          <!--              class="text-base font-semibold text-white font-bold"-->
          <!--              :style="getCategoryColorClass(category.id).bubble"-->
          <!--            >-->
          <!--              {{ getCategoryInitials(category.name) }}-->
          <!--            </span>-->
          <!--          </div>-->

          <div class="ml-2 flex-grow">
            <div class="flex items-center justify-between">
              <div>
                <span
                  class="text-gray-500"
                >{{ category.name }}</span>
              </div>

              <span class="text-base text-gray-500 font-light"><i class="fa-regular fa-chevron-right" /></span>
            </div>
          </div>
        </div>
      </RouterLink>
    </li>
  </ul>
</template>

<script setup>
defineProps({
  categories: {
    type: Array,
    default: () => [],
  },
});
const CATEGORY_COLORS_CACHE = {};

function getRandomColor(categoryId) {
  if (CATEGORY_COLORS_CACHE[categoryId]) {
    return CATEGORY_COLORS_CACHE[categoryId];
  }

  let hue;

  do {
    hue = Math.floor(Math.random() * 360);
  } while (hue > 240 && hue < 120);

  let pastel = 'hsl(' + hue + ', 100%, 80%)';
  let dark = 'hsl(' + hue + ', 100%, 20%)';

  CATEGORY_COLORS_CACHE[categoryId] = {
    background: `background-color: ${pastel};`,
    text: `color: ${pastel};`,
    bubble: `color: ${dark};`,
  };

  return CATEGORY_COLORS_CACHE[categoryId];
}

const getCategoryColorClass = (categoryId) => getRandomColor(categoryId);

const getCategoryInitials = category => {
  return category.split(' ').slice(0, 2).map(word => word[0].toUpperCase()).join('');
};

</script>

<template>
  <PageLoader
    :is-loading="isLoading"
    :error="error"
    loading-text="Loading resources..."
  >
    <AppHeader>
      <DefaultHeader back-route-name="app_matter_list" />
    </AppHeader>

    <AppBody>
      <PageContentLoader
        :is-loading="isLoading"
        :error="error"
        loading-text="Loading resources..."
      >
        <template v-if="!resources.length">
          <NoResults>
            There are no resources!
          </NoResults>
        </template>

        <template v-else>
          <ResourceList :resources="resources" />
        </template>
      </PageContentLoader>
    </AppBody>
  </PageLoader>
</template>

<script setup>
/*
 * Handles the logic to list matters and provides the data to be received in the correct component:
 * Data to be provided for Header, Body and Footer components.
 */
import { onMounted, ref } from 'vue';

import { AppHeader, AppBody } from '@/components';
import { ResourceList } from '@/components/lists';
import { DefaultHeader } from '@/components/headers';
import { PageLoader, PageContentLoader } from '@/components/loaders';
import { NoResults } from '@/components/notices';

import { useHttp } from '@/composables';
import { HttpStatus } from '@/const';

const isLoading = ref(true);
const error = ref(null);
const resources = ref(null);

onMounted(async () => {
  await loadResources();
});

const loadResources = async () => {
  const response = await useHttp().get('/resources');

  if (response.statusCode === HttpStatus.OK) {
    resources.value = response.data.items;
  } else {
    error.value = 'Failed to load resources';
  }

  isLoading.value = false;
};
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div>
      <div class="mb-4">
        <label
          class="sr-only"
          for="email"
        >Email</label>
        <input
          id="email"
          v-model="formData.email"
          class="text-input"
          type="text"
          name="email"
          placeholder="Email"
        >
      </div>

      <DefaultButton :disabled="isSubmitting">
        {{ isSubmitting ? 'Submitting..' : 'Submit' }}
      </DefaultButton>

      <BackButton :back-route-name="{name: 'app_security_login'}">
        Back to login
      </BackButton>
    </div>
  </form>
</template>

<script setup>
// Package Imports
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { BackButton, DefaultButton } from '@/components/buttons';

import { useHttp, useToast } from '@/composables';
import { HttpStatus } from '@/const';
import { useSecurityStore } from '@/store';

// Composables
const router = useRouter();
const store = useSecurityStore();
const toast = useToast();

// Refs & Reactive Properties && Computed Properties
const formData = reactive({
  email: process.env.NODE_ENV === 'development' ? 'richard.keller@creationlabs.co.za' : null,
});

const isSubmitting = ref(false);

// Functions
const handleSubmit = async () => {
  isSubmitting.value = true;

  const response = await useHttp().post(
    '/auth/forgot-password',
    { email: formData.email },
  );

  if (response.statusCode === HttpStatus.OK) {
    await store.$patch({
      completeEmail: formData.email,
    });
    await router.push({ name: 'app_security_forgot_password_complete' });
  } else {
    toast.error('Unable to send password reset email. Please try again');
  }

  isSubmitting.value = false;
};

</script>

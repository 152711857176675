<template>
  <div class="">
    <form @submit.prevent="handleSubmit">
      <template v-if="errors">
        <DefaultFormErrors
          :error="error"
          :errors="errors"
        />
      </template>

      <div class="mt-3">
        <div class="mb-4">
          <label
            class="sr-only"
            for="firstName"
          >First name</label>
          <input
            id="firstName"
            v-model="formData.firstName"
            class="text-input"
            type="text"
            placeholder="First name"
          >
        </div>

        <div class="mb-4">
          <label
            class="sr-only"
            for="lastName"
          >Last name</label>
          <input
            id="lastName"
            v-model="formData.lastName"
            class="text-input"
            type="text"
            placeholder="Last name"
          >
        </div>

        <div class="mb-4">
          <label
            class="sr-only"
            for="email"
          >email</label>
          <input
            id="email"
            v-model="formData.email"
            class="text-input"
            type="email"
            placeholder="Email"
          >
        </div>

        <div class="mb-4">
          <label
            class="sr-only"
            for="password"
          >Password</label>
          <input
            id="password"
            v-model="formData.password"
            class="text-input"
            type="password"
            placeholder="Password"
          >
        </div>

        <div class="mb-4">
          <label
            class="sr-only"
            for="confirmPassword"
          >Confirm password</label>
          <input
            id="confirmPassword"
            v-model="formData.confirmPassword"
            class="text-input"
            type="password"
            placeholder="Confirm password"
          >
        </div>

        <div class="flex space-x-4 my-6">
          <input
            id="disclaimer"
            v-model="formData.agreed"
            class="flex-shrink-0"
            type="checkbox"
          >

          <label for="disclaimer">
            By signing up, you accept the
            <a href="https://legal-leaders.co.za/privacy-policy/">Terms of Service</a> and the <a href="https://legal-leaders.co.za/privacy-policy/">Privacy Policy</a>
          </label>
        </div>

        <div class="col-12 mb-6 text-center">
          <DefaultButton
            :disabled="isSubmitting || !canSubmit"
            button-colour-class="btn-primary-yellow"
          >
            <slot name="buttonText">
              <template v-if="isSubmitting">
                Creating account...
              </template>

              <template v-else>
                Create account
              </template>
            </slot>
          </DefaultButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { HttpStatusCode } from 'axios';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useHttp, useToast } from '@/composables';
import { useSecurityStore } from '@/store';
import { DefaultButton } from '@/components/buttons';
import { DefaultFormErrors } from '@/components/errors';

const router = useRouter();
const store = useSecurityStore();
const toast = useToast();

const formData = reactive({
  firstName: process.env.NODE_ENV === 'development' ? 'Richard' : null,
  lastName: process.env.NODE_ENV === 'development' ? 'Keller' : null,
  email: process.env.NODE_ENV === 'development' ? 'richard.keller@creationlabs.co.za' : null,
  password: process.env.NODE_ENV === 'development' ? 'admin123': null,
  confirmPassword: process.env.NODE_ENV === 'development' ? 'admin123' : null,
  agreed: process.env.NODE_ENV === 'development' ? false : null,
});
const isSubmitting = ref(false);
const canSubmit = computed(() => formData.agreed && formData.confirmPassword === formData.password);
const error = ref(null);
const errors = ref(null);

const handleSubmit = async () => {
  isSubmitting.value = true;
  errors.value = null;

  const { firstName, lastName, email, password } = formData;
  const response = await useHttp().post('/auth/sign-up', { firstName, lastName, email, password });

  if (response.statusCode === HttpStatusCode.Ok) {
    await store.$patch({
      completeEmail: email,
    });
    await router.push({ name: 'app_security_sign_up_complete' });
  } else if (response.statusCode === HttpStatusCode.BadRequest) {
    error.value = response.exception;
    errors.value = response.errors;
  } else {
    toast.error(response.exception);
  }

  isSubmitting.value = false;
};
</script>

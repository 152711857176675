<template>
  <PageWrapper>
    <AppHeader>
      <DefaultHeader back-route-name="app_matter_list" />
    </AppHeader>

    <AppBody>
      <PageContentLoader
        :is-loading="isLoading"
        :error="error"
        loading-text="Loading categories..."
      >
        <template v-if="!categories.length">
          <NoResults>
            There are no categories!
          </NoResults>
        </template>

        <template v-else>
          <CategoryList :categories="categories" />
        </template>
      </PageContentLoader>
    </AppBody>
  </PageWrapper>
</template>

<script setup>
/*
 * Handles the logic to list matters and provides the data to be received in the correct component:
 * Data to be provided for Header, Body and Footer components.
 */
import { onMounted, ref } from 'vue';

// Component Imports
import { AppBody, AppHeader, NoResults } from '@/components';
import { PageContentLoader } from '@/components/loaders';
import { DefaultHeader } from '@/components/headers';
import { CategoryList } from '@/components/lists';
import { PageWrapper } from '@/components/wrappers';

// Composables Imports
import { useHttp } from '@/composables';
import { HttpStatus } from '@/const';

const categories = ref(null);
const isLoading = ref(true);
const error = ref(null);

onMounted(async () => {
  await loadCategories();
});

const loadCategories = async () => {
  const response = await useHttp().get('/categories');

  if (response.statusCode === HttpStatus.OK) {
    categories.value = response.data.items;
  } else {
    error.value = 'Failed to load categories';
  }

  isLoading.value = false;
};
</script>

<template>
  <span class="bouncing-dots"><span class="dot">.</span><span class="dot">.</span><span class="dot">.</span></span>
</template>

<style>
.dot {
  font-weight: bold;
  margin-right: 3px;
  display: inline-block;
  animation-name: bouncing;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-delay: 250ms;
  &:nth-child(2) {
    animation-delay: 500ms;
  }
  &:nth-child(3) {
    animation-delay: 750ms;
  }
}

@keyframes bouncing {
  0% {
    transform: none;
  }

  33% {
    transform: translateY(-.5em);
  }

  66% {
    transform: none;
  }
}
</style>

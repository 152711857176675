<template>
  <SecurityFormWrapper>
    <AppBody>
      <div>
        <i class="fa-duotone fa-user-secret fa-fw fa-fade fa-bounce text-4xl text-primary text-center block mx-auto" />
        <div class="flex items-end mt-6 space-x-0.5">
          <p class="text-center text-primary text-lg mb-0">
            Anonymising your chat. Please wait
          </p>
          <div>
            <i
              class="fa-solid fa-circle fa-beat-fade w-1"
              style="--fa-animation-duration: 1000ms;"
            /> <i
              class="fa-solid fa-circle fa-beat-fade w-1 duration-100"
              style="--fa-animation-duration: 1000ms; --fa-animation-delay: 300ms;"
            /> <i
              class="fa-solid fa-circle fa-beat-fade w-1"
              style="--fa-animation-duration: 1000ms; --fa-animation-delay: 400ms;"
            />
          </div>
        </div>
      </div>
    </AppBody>
  </SecurityFormWrapper>
</template>

<script setup>
import { HttpStatusCode } from 'axios';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';

import { AppBody } from '@/components';
import { SecurityFormWrapper } from '@/components/wrappers';

import { useHttp } from '@/composables';
import { useSecurityStore } from '@/store';

const store = useSecurityStore();
const router = useRouter();

onMounted(async () => {
  const startTime = performance.now();

  const response = await useHttp().post('/auth/sign-up/anonymous', null);

  if (response.statusCode === HttpStatusCode.Ok) {
    store.user = response.data;

    const endTime = performance.now();
    const elapsedTime = endTime - startTime;
    const remainingTime = 2000 - elapsedTime > 0 ? 2000 - elapsedTime : 0;

    // wait for the remaining time if the elapsedTime is less than 2 seconds
    setTimeout(async () => {
      await router.push({ name: 'app_matter_new' });
    }, remainingTime);
  }
});
</script>

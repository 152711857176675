<template>
  <!-- Matters List View -->
  <PageWrapper>
    <AppHeader>
      <LogoHeader>My Matters</LogoHeader>
    </AppHeader>

    <AppBody>
      <PageContentLoader
        :is-loading="isLoading"
        :error="error"
        loading-text="Loading matters..."
      >
        <template v-if="!matters.length">
          <NoResults>
            Your Matters list is empty!
          </NoResults>
        </template>

        <template v-else>
          <MatterList :matters="matters" />
        </template>
      </PageContentLoader>
    </AppBody>
  </PageWrapper>
</template>

<script setup>
import { HttpStatusCode } from 'axios';
import { onMounted, ref } from 'vue';

import { AppHeader, AppBody } from '@/components';
import { LogoHeader } from '@/components/headers';
import { MatterList } from '@/components/lists';
import { PageContentLoader } from '@/components/loaders';
import { NoResults } from '@/components/notices';
import { PageWrapper } from '@/components/wrappers';

import { useHttp } from '@/composables';

const isLoading = ref(true);
const error = ref(null);
const matters = ref(null);

onMounted(async () => {
  const response = await useHttp().get('/matters');

  if (response.statusCode === HttpStatusCode.Ok) {
    matters.value = response.data.items;
  } else {
    error.value = response.exception;
  }

  isLoading.value = false;
});

</script>

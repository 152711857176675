<template>
  <SecurityFormWrapper>
    <template v-if="error">
      {{ error }}
    </template>

    <template v-else>
      <p>Reset your password below</p>

      <ResetPasswordForm />
    </template>
  </SecurityFormWrapper>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { ResetPasswordForm } from '@/components/forms';
import { SecurityFormWrapper } from '@/components/wrappers';

const token = useRoute().query.token;
const error = ref(null);

if (!token) {
  error.value = 'This password reset link is either invalid or has expired.';
}
</script>

import { useDayjs } from '@/composables/DayJs.js';

const formatShortDate = dateString => {
  if (!dateString) {
    return null;
  }

  const dayJs = useDayjs();

  return dayJs(dateString).format('DD MMM YYYY');
};

const formatFromNow = dateString => {
  if (!dateString) {
    return null;
  }

  const dayJs = useDayjs();

  return dayJs(dateString).fromNow();
};

const formatRelative = dateString => {
  if (!dateString) {
    return null;
  }

  const dayJs = useDayjs();
  const now = dayJs();
  const inputDate = dayJs(dateString);

  let format;

  /*
   *  1. If the day is the same, display only the minute and second
   *  2. If the year is the same, don't display the year
   *  3. Otherwise, if the date is a different year, display the day, month and year
   */
  if (inputDate.dayOfYear() === now.dayOfYear()) {
    format = 'HH:mm';
  } else if (inputDate.year() === now.year()) {
    format = 'DD MMM';
  } else {
    format ='DD/MM/YYYY';
  }

  return inputDate.format(format);
};

export const useDateFormatter = () => {
  return {
    formatShortDate,
    formatFromNow,
    formatRelative,
  };
};

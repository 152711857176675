import { createToastInterface, POSITION, TYPE } from 'vue-toastification';

const pluginOptions = {
  timeout: 4000,
  position: POSITION.BOTTOM_LEFT,
  toastClassName: 'toast !p-4',
  bodyClassName: 'font-sans text-white text-base flex items-center',
  transition: 'Vue-Toastification__fade',
  toastDefaults: {
    [TYPE.SUCCESS]: {
      icon: 'fas fa-check p-2 bg-bright-green rounded-full text-base h-4 w-4 flex items-center justify-center',
    },
    [TYPE.WARNING]: {
      icon: 'fas fa-exclamation p-2 bg-orange rounded-full text-base h-4 w-4 flex items-center justify-center',
    },
  },
};

export default {
  install(app) {
    const toast = createToastInterface(pluginOptions);

    app.provide('ToastMessageService', toast);
  },
};

<template>
  <section class="bg-gray-100 dark:bg-gray-900 h-screen">
    <div class="container-padding flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <!--      <img-->
      <!--        alt="Legal Leaders Logo"-->
      <!--        class="block w-48"-->
      <!--        :src="Logo"-->
      <!--      >-->
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl uppercase font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
            Virtual <br><span class="text-primary text-xl">AI</span> Lawyer
          </h1>

          Your sign up was successful. Please check your inbox (<strong>{{ store.completeEmail }}</strong>) for a link to activate your account.
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useSecurityStore } from '@/store';

const store = useSecurityStore();
</script>

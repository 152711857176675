<template>
  <!-- Logo Header -->
  <div class="flex justify-between items-center p-3.5">
    <h1 class="mb-0">
      <slot>{{ pageTitle }}</slot>
    </h1>
    <img
      class="block w-40"
      alt="logo"
      :src="Logo"
    >
  </div>
</template>

<script setup>
import { usePageTitle } from '@/composables';
import Logo from '@/assets/images/logo.webp';

const pageTitle = usePageTitle();

</script>

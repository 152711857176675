import { createPinia } from 'pinia';
import { createApp } from 'vue';
import 'vue-toastification/dist/index.css';

import { DayjsPlugin, EmitterPlugin, ToastPlugin } from '@/plugins';
import { router } from '@/router/router';
import App from './App.vue';

import '@/fontAwesome';
import '@/assets/styles/main.scss';

const app = createApp(App);
const pinia = createPinia();

const useMockApi = process.env.VUE_APP_USE_MOCK_API === 'true';

const vFocus = {
  mounted: el => el.focus(),
};

const startVueApp = async () => {
  // Only enable mocking in development
  if (useMockApi && process.env.NODE_ENV === 'development') {
    const worker = await import('@/mocks/browser');

    worker.startMockServer();
  }

  app
    .use(pinia)
    .use(router)
    .use(EmitterPlugin)
    .use(ToastPlugin)
    .use(DayjsPlugin);

  app.directive('focus', vFocus);

  app.mount('#app');
};

startVueApp();

document.documentElement.classList.remove('dark');

// // On page load or when changing themes, best to add inline in `head` to avoid FOUC
// if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
//   document.documentElement.classList.add('dark')
// } else {
//   document.documentElement.classList.remove('dark')
// }
//
// // Whenever the user explicitly chooses light mode
// localStorage.theme = 'light'
//
// // Whenever the user explicitly chooses dark mode
// localStorage.theme = 'dark'
//
// // Whenever the user explicitly chooses to respect the OS preference
// localStorage.removeItem('theme')

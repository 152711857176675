<template>
  <div class="mx-auto">
    <form @submit.prevent="handleSubmit">
      <template v-if="error">
        <DefaultFormErrors
          :error="error"
          :errors="errors"
        />
      </template>

      <div>
        <div class="mb-4">
          <label
            class="sr-only"
            for="firstName"
          >First Name</label>
          <input
            id="firstName"
            v-model="formData.firstName"
            class="text-input"
            type="text"
            name="firstName"
          >
        </div>

        <div class="mb-4">
          <label
            class="sr-only"
            for="lastName"
          >Last Name</label>
          <input
            id="lastName"
            v-model="formData.lastName"
            class="text-input"
            type="text"
            name="lastName"
          >
        </div>

        <div class="col-12 text-center">
          <button
            class="w-full btn btn-primary"
            :disabled="isSubmitting"
          >
            <template v-if="isSubmitting">
              Updating...
            </template>

            <template v-else>
              Update name
            </template>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
// Package Imports
import { HttpStatusCode } from 'axios';
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useHttp, useToast } from '@/composables';
import { HttpStatus } from '@/const';
import { useSecurityStore } from '@/store';
import { DefaultFormErrors } from '@/components/errors';

// Composables
const toast = useToast();
const store = useSecurityStore();
const router = useRouter();

// Refs & Reactive Properties && Computed Properties
const formData = reactive({
  firstName: store.user.firstName,
  lastName: store.user.lastName,
});
const isSubmitting = ref(false);
const error = ref(null);
const errors = ref(null);

// Functions
const handleSubmit = async () => {
  isSubmitting.value = true;

  const response = await useHttp().put(
    '/profile',
    { firstName: formData.firstName, lastName: formData.lastName },
  );

  if (response.statusCode === HttpStatus.OK) {
    await store.$patch({ user: response.data });

    toast.success('Ton profil a ete mis a jour');

    await router.push({ name: 'app_profile_show' });
  } else if (response.statusCode === HttpStatusCode.BadRequest) {
    error.value = response.exception;
    errors.value = response.errors;
  } else {
    toast.error('Unable to update profile. Please try again');
  }

  isSubmitting.value = false;
};
</script>

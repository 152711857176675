<template>
  <PageWrapper>
    <AppHeader>
      <DefaultHeader
        back-route-name="app_profile_index"
        :show-back-button="!store.isAnonymousUser"
      />
    </AppHeader>

    <AppBody class="pb-0">
      <template v-if="store.isAnonymousUser">
        <div
          class="mb-10"
        >
          <p>Register this anonymous account</p>

          <RouterLink
            :to="{ name: 'app_security_register_anonymous_account' }"
            class="btn btn-primary"
          >
            Register your account
          </RouterLink>
        </div>
      </template>

      <div class="space-y-3">
        <div class="border-b pb-3">
          <span class="text-sm">Name</span>

          <div class="flex justify-between">
            <span class="text-black">{{ name }}</span>

            <template v-if="!store.isAnonymousUser">
              <RouterLink :to="{ name: 'app_profile_update' }">
                <i class="fa-solid fa-pen text-black" />
              </RouterLink>
            </template>
          </div>
        </div>

        <template v-if="!store.isAnonymousUser">
          <div class="border-b pb-3">
            <span class="text-sm">Phone number</span>

            <div class="flex">
              <span class="text-black">{{ store.user.phone || 'N/A' }}</span>
            </div>
          </div>

          <div class="pb-3">
            <span class="text-sm">Email</span>

            <div class="flex">
              <span class="text-black">{{ store.user.email || 'N/A' }}</span>
            </div>
          </div>
        </template>
      </div>
    </AppBody>

    <AppFooter>
      <DefaultButton
        button-colour-class="btn-secondary"
        external-link="https://legal-leaders.co.za/privacy-policy/"
      >
        View our privacy policy
      </DefaultButton>
      <DefaultButton
        button-colour-class="btn-link"
        :to="{ name: 'app_image_credits' }"
      >
        Image credits
      </DefaultButton>
    </AppFooter>
  </PageWrapper>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

import { AppBody, AppHeader, AppFooter } from '@/components';
import { DefaultButton } from '@/components/buttons';
import { DefaultHeader } from '@/components/headers';
import { PageWrapper } from '@/components/wrappers';

import { useSecurityStore } from '@/store';

const store = useSecurityStore();
const router = useRouter();

const name = computed(() => store.isAnonymousUser ? store.user.username : `${store.user.firstName} ${store.user.lastName}`);

onBeforeMount(async () => {
  if (store.isAnonymousUser) {
    await router.back();
  }
});
</script>

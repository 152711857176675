<template>
  <AppHeader>
    <LogoHeader>Your Account</LogoHeader>
  </AppHeader>

  <AppBody class="pb-0">
    <ul>
      <SelectListItem route-name="app_profile_show">
        Your Profile
      </SelectListItem>

      <SelectListItem route-name="app_profile_change_password">
        Change Your Password
      </SelectListItem>

      <SelectListItem @click="logout">
        Logout
      </SelectListItem>
    </ul>
  </AppBody>

  <AppFooter>
    <DefaultButton
      button-colour-class="btn-secondary"
      external-link="https://legal-leaders.co.za/privacy-policy/"
    >
      View our privacy policy
    </DefaultButton>
    <DefaultButton
      button-colour-class="btn-link"
      :to="{ name: 'app_image_credits' }"
    >
      Image credits
    </DefaultButton>
  </AppFooter>
</template>

<script setup>
import { HttpStatusCode } from 'axios';
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

import { AppBody, AppFooter, AppHeader } from '@/components';
import { DefaultButton } from '@/components/buttons';
import { SelectListItem } from '@/components/lists';

import { useHttp } from '@/composables';
import { useSecurityStore } from '@/store';
import { LogoHeader } from '@/components/headers';

const store = useSecurityStore();
const router = useRouter();

onBeforeMount(async () => {
  if (store.isAnonymousUser) {
    await router.back();
  }
});

const logout = async () => {
  const response = await useHttp().post('/auth/logout', null);

  if (response.statusCode === HttpStatusCode.Ok) {
    store.$reset();

    await router.push({ name: 'app_default' });
  }
};
</script>

<template>
  <div>
    <AppHeader>
      <DefaultHeader back-route-name="app_security_forgot_password" />
    </AppHeader>

    <AppBody>
      <SecurityFormWrapper>
        <h1 class="text-xl uppercase font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
          Virtual <br><span class="text-primary text-xl">AI</span> Lawyer
        </h1>

        <p>
          We've sent an email to <strong>{{ store.completeEmail }}</strong>
        </p>

        <p>Please check your inbox for an email with a link to reset your password</p>

        <BackButton :back-route-name="{ name: 'app_security_login' }">
          Back to login
        </BackButton>
      </SecurityFormWrapper>
    </AppBody>
  </div>
</template>

<script setup>
import { AppBody, AppHeader } from '@/components';
import { DefaultHeader } from '@/components/headers';

import { useSecurityStore } from '@/store';
import { SecurityFormWrapper } from '@/components/wrappers';
import { BackButton } from '@/components/buttons';

const store = useSecurityStore();
</script>

import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useSecurityStore = defineStore('security', () => {
  const user = ref(null);
  const completeEmail = ref(null);

  const isAuthenticated = computed(() => user.value !== null);
  const isAnonymousUser = computed(() => user.value && user.value.username !== null);

  function $reset() {
    user.value = null;
  }

  return { user, isAuthenticated, completeEmail, $reset, isAnonymousUser };
});

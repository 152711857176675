<template>
  <ul>
    <li
      v-for="form in forms"
      :key="form.id"
    >
      <div
        class="hover:cursor-pointer"
        @click="selectForm(form.id)"
      >
        <div class="flex items-center border-b pt-2.5 pb-3 w-full">
          <div
            class="relative rounded-full  flex justify-center items-center"
          >
            <i class="fa-duotone fa-clipboard text-primary/60 mt-[3px] text-3xl" />
            <span class="text-sm font-semibold text-primary absolute top-3">
              {{ getCategoryInitials(form.name) }}
            </span>
          </div>

          <div class="ml-2 flex-grow">
            <div class="flex items-center justify-between">
              <div>
                <span
                  class="text-gray-500"
                >{{ form.name }}</span>
              </div>

              <span class="text-base text-gray-500 font-light"><i class="fa-regular fa-chevron-right" /></span>
            </div>
          </div>
        </div>
      </div>
    </li>

    <!--    <li-->
    <!--      v-for="form in forms"-->
    <!--      :key="form.id"-->
    <!--      class="list-select"-->
    <!--    >-->
    <!--      <div class="hover:cursor-pointer">-->
    <!--        <span @click="selectForm(form.id)">{{ form.name }}</span>-->
    <!--        <i class="fa-regular fa-chevron-right" />-->
    <!--      </div>-->
    <!--    </li>-->
  </ul>
</template>

<script setup>
import { useRouter } from 'vue-router';

import { useHttp, useToast } from '@/composables';
import { HttpStatus } from '@/const';

defineProps({
  forms: {
    type: Array,
    default: () => [],
  },
});

const toast = useToast();
const router = useRouter();

const selectForm = async id => {
  const response = await useHttp().post('/matters', { form: id });

  if (response.statusCode === HttpStatus.OK) {
    await router.push({ name: 'app_matter_show', params: { id: response.data.id } });
  } else {
    toast.error('Unable to create matter. Please try again.');
  }
};

const getCategoryInitials = form => {
  return form.split(' ').slice(0, 2).map(word => word[0].toUpperCase()).join('');
};

</script>

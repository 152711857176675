<template>
  <!-- Matters New Select Category Form - App Wrapper -->
  <PageWrapper>
    <AppHeader>
      <DefaultHeader back-route-name="app_matter_new">
        <template v-if="isLoading.category">
          Loading...
        </template>

        <template v-else-if="error.category">
          {{ error.category }}
        </template>

        <template v-else>
          {{ category.name }}
        </template>
      </DefaultHeader>
    </AppHeader>

    <AppBody>
      <PageContentLoader
        :is-loading="isLoading.forms"
        :error="error.forms"
        loading-text="Loading forms..."
      >
        <template
          v-if="!forms.length"
        >
          <NoResults>
            There are no Matter Forms to show!
          </NoResults>
        </template>

        <template v-else>
          <CategoryFormList :forms="forms" />
        </template>
      </PageContentLoader>
    </AppBody>
  </PageWrapper>
</template>

<script setup>
/*
 * Handles the logic to list matters and provides the data to be received in the correct component:
 * Data to be provided for Header, Body and Footer components.
 */
// Package Imports
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

// Component Imports
import { AppBody, AppHeader, NoResults } from '@/components';
import { PageContentLoader } from '@/components/loaders';
import { DefaultHeader } from '@/components/headers';
import { CategoryFormList } from '@/components/lists';
import { PageWrapper } from '@/components/wrappers';

import { useEmitterEvent, useHttp } from '@/composables';
import { Events, HttpStatus } from '@/const';

// Props, refs, reactive properties and computed properties
const categoryId = Number(useRoute().params.categoryId);
const forms = ref(null);
const category = ref(null);
const isLoading = ref({
  category: true,
  forms: true,
});
const error = ref({
  category: null,
  forms: null,
});

// Lifecycle hooks (in order of execution)
onMounted(async () => {
  await Promise.all([
    loadCategory(),
    loadForms(),
  ]);

  isLoading.value.category = false;
  isLoading.value.forms = false;
});

// Methods
const selectForm = event => {
  alert(`Form with ID ${event.id} selected`);

  // TODO: create a new matter
};

const loadCategory = async () => {
  const response = await useHttp().get(`/categories/${categoryId}`);

  if (response.statusCode === HttpStatus.OK) {
    category.value = response.data;
  } else {
    error.value.category = 'Failed to load category';
  }

  isLoading.value.category = false;
};

const loadForms = async () => {
  const response = await useHttp().get(`/forms?category=${categoryId}`);

  if (response.statusCode === HttpStatus.OK) {
    forms.value = response.data.items;
  } else {
    error.value.forms = 'Failed to load forms';
  }

  isLoading.value.forms = false;
};

// Watchers

// Functions and method calls (create functions first!)
useEmitterEvent(Events.matter.selectCategoryForm, selectForm);
</script>

import { createRouter, createWebHistory } from 'vue-router';

import {
  DefaultView,
  ImageCreditsView,
  NotFoundView,
} from '@/views';

import { matterRoutes } from '@/router/matters';
import { profileRoutes } from '@/router/profile';
import { resourceRoutes } from '@/router/resources';
import { securityRoutes } from '@/router/security';

const scrollBehavior = (to, from, savedPosition) => {
  // Scroll to top if on same route
  if (to.name === from.name) {
    to.meta?.scrollPos && (to.meta.scrollPos.top = 0);
    return { left: 0, top: 0 };
  }
  return savedPosition ||
      to.meta?.scrollPos ||
      { left: 0, top: 0 };
};
export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...securityRoutes,
    {
      path: '/image-credits',
      name: 'app_image_credits',
      meta: { title: 'Image Credits' },
      component: ImageCreditsView,
    },
    {
      path: '/',
      name: 'app_default',
      component: DefaultView,
    },
    ...matterRoutes,
    ...profileRoutes,
    ...resourceRoutes,
    {
      path: '/:notFound',
      name: 'app_not_found',
      component: NotFoundView,
      meta: { title: 'Page Not Found' },
    },
  ],
  scrollBehavior,
});

router.afterEach(to => {
  let title;

  // TODO: the page title should be an environment variable
  if (to.meta.title) {
    title = `${to.meta.title} | Virtual Lawyer Assistant`;
  } else {
    title = 'Virtual Lawyer Assistant';
  }

  document.title = title;
});

import {
  LoginView,
  SignUpView,
  SignUpCompleteView,
  ForgotPasswordView,
  ForgotPasswordCompleteView,
  ResetPasswordView,
  ResetPasswordCompleteView,
  VerifyEmailView,
  VerifyEmailCompleteView,
  RegisterAnonymousAccount,
} from '@/views/security';
import AnonymousChatView from '@/views/security/AnonymousChatView.vue';

export const securityRoutes = [
  {
    path: '/login',
    name: 'app_security_login',
    meta: {
      title: 'Log In',
      redirectAuthenticatedUsers: true,
    },
    component: LoginView,
  },
  {
    path: '/sign-up',
    name: 'app_security_sign_up',
    meta: {
      title: 'Sign Up',
      redirectAuthenticatedUsers: true,
    },
    component: SignUpView,
  },
  {
    path: '/sign-up/complete',
    name: 'app_security_sign_up_complete',
    meta: {
      title: 'Sign Up Complete',
      redirectAuthenticatedUsers: true,
    },
    component: SignUpCompleteView,
  },
  {
    path: '/register-anonymous-account',
    name: 'app_security_register_anonymous_account',
    meta: {
      title: 'Register Your Account',
      redirectAuthenticatedUsers: true,
    },
    component: RegisterAnonymousAccount,
  },
  {
    path: '/forgot-password',
    name: 'app_security_forgot_password',
    meta: {
      title: 'Forgot Password',
      redirectAuthenticatedUsers: true,
    },
    component: ForgotPasswordView,
  },
  {
    path: '/forgot-password/complete',
    name: 'app_security_forgot_password_complete',
    meta: {
      title: 'Forgot Password Complete',
      redirectAuthenticatedUsers: true,
    },
    component: ForgotPasswordCompleteView,
  },
  {
    path: '/reset-password',
    name: 'app_security_reset_password',
    meta: {
      redirectAuthenticatedUsers: true,
    },
    component: ResetPasswordView,
  },
  {
    path: '/reset-password/complete',
    name: 'app_security_reset_password_complete',
    meta: {
      redirectAuthenticatedUsers: true,
    },
    component: ResetPasswordCompleteView,
  },
  {
    path: '/anonymous-chat',
    name: 'app_security_anonymous_chat',
    meta: {
      redirectAuthenticatedUsers: true,
      requiresAuthentication: true,
    },
    component: AnonymousChatView,
  },
  {
    path: '/verify-email',
    name: 'app_security_verify_email',
    component: VerifyEmailView,
  },
  {
    path: '/verify-email/complete',
    name: 'app_security_verify_email_complete',
    component: VerifyEmailCompleteView,
    meta: {
      hideNavBar: true,
      redirectAuthenticatedUsers: true,
    },
  },
];

<template>
  <ul class="space-y-4">
    <li
      v-for="resource in resources"
      :key="resource.id"
      class="cursor-pointer"
    >
      <a
        :href="resource.url"
        target="_blank"
        class="no-underline"
      >
        <div class="flex items-center border-b pt-2 pb-3 w-full">
          <div class="text-primary rounded-full text-center flex justify-center items-center">
            <i
              class="fa-duotone text-primary mt-[3px] text-3xl"
              :class="getIconForFileExtension(resource.name)"
            />
          </div>

          <div class="ml-2 text-xs">
            <div>
              <p class="text-xs sm:text-sm mb-0.5">
                <strong class="text-black">{{ resource.name }}</strong>&nbsp;
                <br>
              </p>
            </div>

            <template v-if="resource.description">
              <span class="text-neutral-500">{{ resource.description }}</span>
            </template>
          </div>
        </div>
      </a>
    </li>
  </ul>
</template>

<script setup>
defineProps({
  resources: {
    type: Array,
    default: () => [],
  },
});

// Function to get the file extension
const getFileExtension = (filename) => {
  return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
};

// Here you would need to map extensions to actual icons.
const getIconForFileExtension = filename => {
  let extension = getFileExtension(filename);

  switch (extension) {
    case 'pdf':
      return 'fa-file-pdf text-red-600';
    case 'doc':
      return 'fa-file-doc text-blue-600';
    case 'png':
      return 'fa-file-png text-secondary';
    case 'jpg':
      return 'fa-file-jpg text-secondary';
    case 'xlsx':
      return 'fa-file-xls text-green-600';
    case 'csv':
      return 'fa-file-csv text-green-600';
    default:
      return 'fa-file text-primary';
  }
};

</script>

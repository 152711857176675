<template>
  <div>
    <AppHeader>
      <DefaultHeader back-route-name="app_profile_index" />
    </AppHeader>

    <AppBody>
      <ChangePasswordForm />
    </AppBody>
  </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

import { AppHeader, AppBody } from '@/components';
import { ChangePasswordForm } from '@/components/forms';
import { DefaultHeader } from '@/components/headers';

import { useSecurityStore } from '@/store';

const router = useRouter();
const store = useSecurityStore();

onBeforeMount(async () => {
  if (store.isAnonymousUser) {
    await router.back();
  }
});
</script>

<template>
  <AppLoader
    :is-loading="isLoading"
    :error="error"
  >
    <RouterView />

    <template v-if="store.isAuthenticated && (route.meta.hideNavBar !== true)">
      <AppNavBar id="navbar" />
    </template>
  </AppLoader>
</template>

<script setup>
// This component handles authentication and redirection to the correct route
import { HttpStatusCode } from 'axios';
import { ref, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { AppNavBar } from '@/components';
import { AppLoader } from '@/components/loaders';

import { useHttp } from '@/composables';
import { HttpStatus } from '@/const';
import { useSecurityStore } from '@/store';

const store = useSecurityStore();
const route = useRoute();
const router = useRouter();
const isLoading = ref(true);
const error = ref(null);

onBeforeMount(async () => {
  const response = await useHttp().get('/profile');

  if (response.statusCode === HttpStatus.OK) {
    store.user = response.data;
  } else if (response.statusCode !== HttpStatusCode.Unauthorized) {
    error.value = response.exception;
  }

  isLoading.value = false;

  if (!store.user && route.meta.requiresAuthentication) {
    await router.push({ name: 'app_security_login' });
  }

  if (store.isAuthenticated && route.meta.redirectAuthenticatedUsers) {
    await router.push({ name: 'app_default' });
  }
});
</script>

import { library, dom } from '@fortawesome/fontawesome-svg-core';

// Solid - General
import { faArrowRightFromBracket as fasArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons/faArrowRightFromBracket';
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faBuilding as fasBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft as fasChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faDownload as fasDownload } from '@fortawesome/pro-solid-svg-icons/faDownload';
import { faEllipsis as fasEllipsis } from '@fortawesome/pro-solid-svg-icons/faEllipsis';
import { faEllipsisVertical as fasEllipsisVertical } from '@fortawesome/pro-solid-svg-icons/faEllipsisVertical';
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faGear as fasGear } from '@fortawesome/pro-solid-svg-icons/faGear';
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faPen as fasPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTrash as fasTrash } from '@fortawesome/pro-solid-svg-icons/faTrash';
import { faSpinnerScale as fasSpinnerScale } from '@fortawesome/pro-solid-svg-icons/faSpinnerScale';
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faPaperPlaneTop as fasPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons/faPaperPlaneTop';
import { faDiamondHalf as fasDiamondHalf } from '@fortawesome/pro-solid-svg-icons/faDiamondHalf';
import { faMessage as fasMessage } from '@fortawesome/pro-solid-svg-icons/faMessage';
import { faMessages as fasMessages } from '@fortawesome/pro-solid-svg-icons/faMessages';
import { faComment as fasComment } from '@fortawesome/pro-solid-svg-icons/faComment';

// Menu icons
import { faCirclePlus as fasCirclePlus } from '@fortawesome/pro-solid-svg-icons/faCirclePlus';
import { faMessageDots as fasMessageDots } from '@fortawesome/pro-solid-svg-icons/faMessageDots';
import { faMessageDots as farMessageDots } from '@fortawesome/pro-regular-svg-icons/faMessageDots';
import { faFile as fasFile } from '@fortawesome/pro-solid-svg-icons/faFile';
import { faFile as farFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faCalendar as fasCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar';
import { faCalendar as falCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUser as farUser } from '@fortawesome/pro-regular-svg-icons/faUser';

// Regular
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight as farChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faWifiSlash as farWifiSlash } from '@fortawesome/pro-regular-svg-icons/faWifiSlash';
import { faPenToSquare as fasPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { faBriefcase as fasBriefcase } from '@fortawesome/pro-regular-svg-icons/faBriefcase';
import { faFileCircleQuestion as farFileCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faFileCircleQuestion';
import { faClipboardQuestion as falClipboardQuestion } from '@fortawesome/pro-light-svg-icons/faClipboardQuestion';
import { faBan as farBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faCrab as farCrab } from '@fortawesome/pro-regular-svg-icons/faCrab';
import { faEllipsisVertical as farEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical';
import { faCircleNotch as farCircleNotch } from '@fortawesome/pro-regular-svg-icons/faCircleNotch';
import { faCalendar as farCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar';

// Duo-tone
import { faSort as fadSort } from '@fortawesome/pro-duotone-svg-icons/faSort';
import { faCalendarDays as fadCalendarDays } from '@fortawesome/pro-duotone-svg-icons/faCalendarDays';
import { faFaceMonocle as fadFaceMonocle } from '@fortawesome/pro-regular-svg-icons/faFaceMonocle';
import { faPooStorm as fadPooStorm } from '@fortawesome/pro-duotone-svg-icons/faPooStorm';
import { faFaceExplode as fadFaceExplode } from '@fortawesome/pro-duotone-svg-icons/faFaceExplode';
import { faCrab as fadCrab } from '@fortawesome/pro-duotone-svg-icons/faCrab';
import { faRaccoon as fadRaccoon } from '@fortawesome/pro-duotone-svg-icons/faRaccoon';
import { faUserSecret as fadUserSecret } from '@fortawesome/pro-duotone-svg-icons/faUserSecret';
import { faMessages as fadMessages } from '@fortawesome/pro-duotone-svg-icons/faMessages';
import { faEyeSlash as fadEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash';
import { faBookBlank as fadBookBlank } from '@fortawesome/pro-duotone-svg-icons/faBookBlank';
import { faClipboard as fadClipboard } from '@fortawesome/pro-duotone-svg-icons/faClipboard';
import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faFile as fadFile } from '@fortawesome/pro-duotone-svg-icons/faFile';
import { faFilePdf as fadFilePdf } from '@fortawesome/pro-duotone-svg-icons/faFilePdf';
import { faFileDoc as fadFileDoc } from '@fortawesome/pro-duotone-svg-icons/faFileDoc';
import { faFileXls as fadFileXls } from '@fortawesome/pro-duotone-svg-icons/faFileXls';
import { faFilePng as fadFilePng } from '@fortawesome/pro-duotone-svg-icons/faFilePng';
import { faFileJpg as fadFileJpg } from '@fortawesome/pro-duotone-svg-icons/faFileJpg';
import { faFileCsv as fadFileCsv } from '@fortawesome/pro-duotone-svg-icons/faFileCsv';

// Add icons to the library
library.add(
  // Solid
  fasEllipsisVertical,
  fasBars,
  fasBuilding,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasCheck,
  fasDownload,
  fasEllipsisVertical,
  fasEnvelope,
  fasCirclePlus,
  fasGear,
  fasTimes,
  fasTrash,
  fasInfoCircle,
  fasSearch,
  fasArrowRightFromBracket,
  fasEllipsis,
  fasPen,
  fasMessageDots,
  fasPenToSquare,
  fasBriefcase,
  fasFile,
  fasCalendar,
  fasUser,
  farUser,
  fasSpinnerScale,
  fasCircle,
  fasPaperPlaneTop,
  fasDiamondHalf,
  fasMessage,
  fasMessages,
  fasComment,
  // Regular
  farChevronLeft,
  farMessageDots,
  farWifiSlash,
  farFile,
  farChevronRight,
  farFileCircleQuestion,
  falClipboardQuestion,
  farBan,
  farCrab,
  farEllipsisVertical,
  farCircleNotch,
  farCalendar,
  // Light
  falCalendar,
  // Duo-tone
  fadSort,
  fadCalendarDays,
  fadFaceMonocle,
  fadPooStorm,
  fadFaceExplode,
  fadCrab,
  fadRaccoon,
  fadUserSecret,
  fadMessages,
  fadEyeSlash,
  fadBookBlank,
  fadClipboard,
  fadSpinnerThird,
  fadFile,
  fadFilePdf,
  fadFileDoc,
  fadFileXls,
  fadFilePng,
  fadFileJpg,
  fadFileCsv,
);

// Watch the DOM and replace any <i> tags with SVG icons
dom.watch();

import {
  MattersListView,
  MattersNewSelectCategory,
  MattersNewSelectCategoryForm,
  MatterChatView,
} from '@/views/matters';

export const matterRoutes = [
  {
    path: '/matters',
    name: 'app_matter_list',
    component: MattersListView,
    meta: { title: 'My Matters', requiresAuthentication: true },
  },
  {
    path: '/matters/new',
    name: 'app_matter_new',
    meta: { title: 'Select a New Matter', requiresAuthentication: true },
    component: MattersNewSelectCategory,
  },
  {
    path: '/matters/new/:categoryId',
    name: 'app_matter_new_select_category_form',
    component: MattersNewSelectCategoryForm,
    meta: { requiresAuthentication: true },
  },
  {
    path: '/matters/:id',
    name: 'app_matter_show',
    component: MatterChatView,
    meta: {
      hideNavBar: true,
      requiresAuthentication: true,
    },
  },
];

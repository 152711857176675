export const apiUrl = process.env.VUE_APP_API_URL;

export const HttpStatus = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

export const Events = {
  matter: {
    selectCategory: 'matter.selectCategory',
    selectCategoryForm: 'matter.selectCategoryForm',
    export: 'matter.export',
  },
  pagination: {
    pageChanged: 'pagination.pageChanged',
  },
  security: {
    logout: 'security.logout',
    signUpComplete: 'security.signUpComplete',
  },
};

<template>
  <div class="mx-auto">
    <form @submit.prevent="handleSubmit">
      <template v-if="error">
        <DefaultFormErrors
          :error="error"
          :errors="errors"
        />
      </template>

      <div>
        <div class="mb-4">
          <label
            class="sr-only"
            for="password"
          >Password</label>
          <input
            id="password"
            v-model="formData.password"
            class="text-input"
            type="password"
            name="password"
          >
        </div>

        <div class="mb-4">
          <label
            class="sr-only"
            for="confirmPassword"
          >Confirm password</label>
          <input
            id="confirmPassword"
            v-model="formData.confirmPassword"
            class="text-input"
            type="password"
          >
        </div>

        <div class="col-12 mb-6 text-center">
          <DefaultButton
            class="w-full btn"
            :class="{ 'btn-primary': canSubmit }"
            :disabled="!canSubmit"
          >
            <template v-if="isSubmitting">
              Submitting...
            </template>

            <template v-else>
              Submit
            </template>
          </DefaultButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
// Package Imports
import { computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { DefaultButton } from '@/components/buttons';

import { useHttp, useToast } from '@/composables';
import { HttpStatus } from '@/const';
import { DefaultFormErrors } from '@/components/errors';
import { HttpStatusCode } from 'axios';

// Composables
const router = useRouter();
const route = useRoute();
const toast = useToast();

// Refs & Reactive Properties && Computed Properties
const formData = reactive({
  password: process.env.NODE_ENV === 'development' ? 'admin123' : null,
  confirmPassword: process.env.NODE_ENV === 'development' ? 'admin123' : null,
});

const isSubmitting = ref(false);
const canSubmit = computed(() => !isSubmitting.value && formData.confirmPassword === formData.password);
const error = ref(null);
const errors = ref(null);

// Functions
const handleSubmit = async () => {
  isSubmitting.value = true;

  const response = await useHttp().post(
    '/auth/password-reset',
    { token: route.query.token, password: formData.password },
  );

  if (response.statusCode === HttpStatus.OK) {
    await router.push({ name: 'app_security_reset_password_complete' });
  } else if (response.statusCode === HttpStatusCode.BadRequest) {
    error.value = response.exception;
    errors.value = response.errors;
  } else {
    toast.error(response.exception);
  }

  isSubmitting.value = false;
};
</script>

<template>
  <div>
    <form @submit.prevent="canSubmit && handleSubmit()">
      <template v-if="error">
        <DefaultFormErrors
          :error="error"
          :errors="errors"
        />
      </template>

      <div>
        <div class="mb-4">
          <label
            class="sr-only"
            for="currentPassword"
          >
            Current password
          </label>

          <input
            id="currentPassword"
            v-model="formData.currentPassword"
            class="text-input"
            type="password"
            placeholder="Current password"
          >
        </div>

        <div class="mb-4">
          <label
            class="sr-only"
            for="password"
          >
            New password
          </label>

          <input
            id="newPassword"
            v-model="formData.newPassword"
            class="text-input"
            type="password"
            placeholder="New password"
          >
        </div>

        <div class="mb-4">
          <label
            class="sr-only"
            for="confirmPassword"
          >
            Confirm password
          </label>

          <input
            id="confirmPassword"
            v-model="formData.confirmPassword"
            class="text-input"
            type="password"
            placeholder="Confirm password"
          >
        </div>

        <div class="col-12 mb-6 text-center">
          <ButtonContainer>
            <DefaultButton
              class="w-full"
              :disabled="isSubmitting || !canSubmit"
            >
              <template v-if="isSubmitting">
                Updating password...
              </template>

              <template v-else>
                Update password
              </template>
            </DefaultButton>
          </ButtonContainer>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { HttpStatusCode } from 'axios';
import { computed, reactive, ref } from 'vue';

import { ButtonContainer, DefaultButton } from '@/components/buttons';
import { DefaultFormErrors } from '@/components/errors';

import { useHttp, useToast } from '@/composables';

const toast = useToast();
const formData = reactive({
  currentPassword: null,
  newPassword: null,
  confirmPassword: null,
});
const isSubmitting = ref(false);
const canSubmit = computed(() => {
  return formData.currentPassword &&
    formData.newPassword &&
    formData.confirmPassword &&
    formData.confirmPassword === formData.newPassword;
});
const error = ref(null);
const errors = ref(null);

// Functions
const handleSubmit = async () => {
  isSubmitting.value = true;

  const response = await useHttp().post(
    '/auth/password-change',
    { currentPassword: formData.currentPassword, newPassword: formData.newPassword },
  );

  if (response.statusCode === HttpStatusCode.Ok) {
    toast.success('Your password has been updated');
  } else if (response.statusCode === HttpStatusCode.BadRequest) {
    error.value = response.exception;
    errors.value = response.errors;
  } else {
    toast.error('Unable to change password. Please try again');
  }

  formData.currentPassword = null;
  formData.newPassword = null;
  formData.confirmPassword = null;

  isSubmitting.value = false;
};
</script>

import { inject, onMounted, onUnmounted } from 'vue';

export const useEmitterEvent = (event, callback) => {
  const emitter = inject('Emitter');

  if (!emitter) throw new Error('Emitter is not provided');

  onMounted(() => emitter.on(event, callback));
  onUnmounted(() => emitter.off(event, callback));
};

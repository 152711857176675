<template>
  <li>
    <template v-if="routeName">
      <RouterLink
        :to="{ name: routeName }"
        class="menu-item hover:text-secondary duration-75"
        active-class="text-secondary"
      >
        <span v-show="currentRoute.name === routeName">
          <i
            class="fa-solid fa-fw mx-auto text-lg md:text-xl text-center mb-1 block"
            :class="icon"
          />
        </span>
        <span v-show="currentRoute.name !== routeName">
          <i
            class="fa-regular fa-fw mx-auto text-lg md:text-xl text-center mb-1 block"
            :class="icon"
          />
        </span>
        <span class="md:text-sm"><slot /></span>
      </RouterLink>
    </template>

    <template v-else>
      <a
        :href="externalLink"
        class="menu-item hover:text-secondary duration-75"
        target="_blank"
      >
        <span>
          <i
            class="fa-regular fa-fw mx-auto text-lg md:text-xl text-center mb-1 block"
            :class="icon"
          />
        </span>
        <span class="md:text-sm"><slot /></span>
      </a>
    </template>
  </li>
</template>

<script setup>

import { useRoute } from 'vue-router';

defineProps({
  icon: {
    type: String,
    default: 'fa-list',
  },
  routeName: {
    type: String,
    default: null,
  },
  externalLink: {
    type: String,
    default: null,
  },
});

const currentRoute = useRoute();

</script>

import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.request.use(config => {
  /*
   * If the data being sent in the request is form data, let the browser
   * automatically set the Content-Type header to multipart/form-data,
   * otherwise send it as application/json for all other requests
   */
  if (config.data && config.data instanceof FormData) {
    delete config.headers['Content-Type'];
  }

  return config;
});

axios.interceptors.response.use(response => {
  return response;
}, async err => {
  // Handle API call failures due to a problem with the user's internet connection
  if (!err.response) {
    const response = {
      statusCode: null,
      exception: 'An unknown error occurred',
    };

    return Promise.reject(response);
  }

  // TODO: We need to put this back in, in some form or other. Perhaps only redirect when the user is on certain pages,
  //  or only redirect when the user is NOT on certain pages...
  // if (err.response.status === HttpStatus.UNAUTHORIZED) {
  //   await router.push({ name: 'app_security_login' });
  // }

  return Promise.reject(err);
});

const defaultHeaders = {
  'Content-Type': 'application/json',
};

export const HttpClient = {
  async request(method, url, body = null, queryParams = null, headers = null) {
    const axiosConfig = { method, url, headers, data: body, params: queryParams };

    try {
      const response = await axios(axiosConfig);

      return response.data;
    } catch (err) {
      if (err.response) {
        return err.response.data;
      }

      return { statusCode: null, exception: 'An unknown error occurred' };
    }
  },
  get(url, query, headers) {
    return this.request('GET', url, null, query, headers || defaultHeaders);
  },
  post(url, body, headers) {
    return this.request('POST', url, body, null, headers || defaultHeaders);
  },
  put(url, body, headers) {
    return this.request('PUT', url, body, null, headers || defaultHeaders);
  },
  delete(url, headers) {
    return this.request('DELETE', url, null, null, headers || defaultHeaders);
  },
};

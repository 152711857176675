<template>
  <div
    class="relative text-neutral-800 p-3.5 border-b"
    :class="{ 'justify-center': center }"
  >
    <div class="absolute left-3.5">
      <RouterLink
        v-if="showBackButton"
        :to="backRoute"
        class="no-underline cursor-pointer text-black block"
      >
        <i class="fa-regular fa-chevron-left fa-fw" />
        <span class="sr-only text-xs">Back to {{ backRoute.meta.title }}</span>
      </RouterLink>
    </div>

    <span class="text-sm block w-full text-center">
      <slot>
        {{ pageTitle }}
      </slot>
    </span>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

import { usePageTitle } from '@/composables';

const props = defineProps({
  backRouteName: {
    type: String,
    default: null,
  },
  center: {
    type: Boolean,
    default: true,
  },
  showBackButton: {
    type: Boolean,
    default: true,
  },
});

const pageTitle = usePageTitle();
const backRoute = useRouter().resolve({ name: props.backRouteName });
</script>


<template>
  <div class="h-full flex flex-col">
    <slot />
  </div>
</template>

<script setup>

</script>

import { HttpClient } from '@/api';

export const useHttp = () => {
  return {
    get: async (url, query = null, headers = null) => HttpClient.get(url, query, headers),
    post: async (url, body, headers = null) => HttpClient.post(url, body, headers),
    put: async (url, body, headers = null) => HttpClient.put(url, body, headers),
    delete: async (url, headers = null) => HttpClient.delete(url, headers),
  };
};

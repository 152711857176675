<template>
  <div
    class="absolute z-30 flex justify-center items-center overflow-hidden inset-0 h-full w-full"
    :class="{ 'position-relative': position === 'relative' }"
  >
    <div class="py-5">
      <div class="text-center">
        <div class="text-center">
          <i class="fas fa-fw text-5xl fa-spinner-scale fa-spin" />
        </div>
        <div class="text-center text-dark-grey mt-5 text-lg">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation',
  props: {
    text: {
      type: String,
      default: 'Loading...',
    },
    position: {
      type: String,
      default: 'absolute',
    },
  },
};
</script>

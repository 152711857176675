import { ResourcesListView } from '@/views/resources';

export const resourceRoutes = [
  {
    path: '/resources',
    name: 'app_resource_list',
    meta: { title: 'Resources', requiresAuthentication: true },
    component: ResourcesListView,
  },
];

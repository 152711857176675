<template>
  <SecurityFormWrapper>
    <div class="text-center">
      <template v-if="verifyingEmail">
        Verifying email...
      </template>

      <template v-else-if="error">
        {{ error }}
      </template>

      <template v-else>
        Your email has successfully been verified. Please

        <RouterLink :to="{name: 'app_security_login'}">
          log in
        </RouterLink>
      </template>
    </div>
  </SecurityFormWrapper>
</template>

<script setup>
import { HttpStatusCode } from 'axios';

import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useHttp } from '@/composables';
import { SecurityFormWrapper } from '@/components/wrappers';

const route = useRoute();

const error = ref(null);
const verifyingEmail = ref(true);

onMounted(async () => {
  await verifyUser();
});

const verifyUser = async () => {
  const response = await useHttp().post(
    '/auth/email-verification',
    { token: route.query.token ?? null },
  );

  if (response.statusCode !== HttpStatusCode.Ok) {
    error.value = response.exception;
  }

  verifyingEmail.value = false;
};
</script>

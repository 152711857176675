import {
  ProfileIndexView,
  ChangePasswordView,
  ProfileShowView,
  ProfileUpdateView,
} from '@/views/profile';

export const profileRoutes = [
  {
    path: '/profile',
    name: 'app_profile_index',
    meta: { title: 'Profile', requiresAuthentication: true },
    component: ProfileIndexView,
  },
  {
    path: '/profile/change-password',
    name: 'app_profile_change_password',
    meta: { title: 'Change Your Password', requiresAuthentication: true },
    component: ChangePasswordView,
  },
  {
    path: '/profile/show',
    name: 'app_profile_show',
    meta: { title: 'Your Profile', requiresAuthentication: true },
    component: ProfileShowView,
  },
  {
    path: '/profile/update',
    name: 'app_profile_update',
    meta: { title: 'Update Name', requiresAuthentication: true },
    component: ProfileUpdateView,
  },
];

<template>
  <!-- App Navbar -->
  <div class="w-full px-2.5 pt-3.5 pb-2 mt-6 bg-primary fixed bottom-0 left-0 right-0 z-50">
    <ul class="flex justify-between max-w-2xl mx-auto">
      <MenuLink
        route-name="app_matter_list"
        icon="fa-message-dots"
      >
        My Matters
      </MenuLink>
      <MenuLink
        route-name="app_resource_list"
        icon="fa-file"
      >
        Resources
      </MenuLink>

      <li>
        <RouterLink
          :to="{ name: 'app_matter_new' }"
          class="menu-item"
          active-class="text-secondary"
        >
          <i class="fa-solid fa-circle-plus fa-fw !block mx-auto !w-11 !h-11 text-center -mt-9 p-1.5 mb-0.5 bg-primary rounded-full" />
          <span class="md:text-sm">New matter</span>
        </RouterLink>
      </li>

      <MenuLink
        external-link="https://legal-leaders.co.za/get-a-lawyer/"
        icon="fa-calendar"
      >
        Book Consult
      </MenuLink>

      <MenuLink
        :route-name="store.isAnonymousUser ? 'app_security_register_anonymous_account' : 'app_profile_index'"
        icon="fa-user"
      >
        {{ store.isAnonymousUser ? 'Register' : 'Profile' }}
      </MenuLink>
    </ul>
  </div>
</template>

<script setup>
import { useSecurityStore } from '@/store';
import { MenuLink } from '@/components/buttons';

const store = useSecurityStore();

</script>

<template>
  <div
    class="flex items-center text-neutral-800 px-3.5 border-b w-full h-16"
  >
    <div class="text-black pr-3">
      <RouterLink
        v-if="showBackButton"
        :to="{ name: 'app_matter_list' }"
        class="no-underline cursor-pointer text-black block"
      >
        <i class="fa-regular fa-chevron-left fa-fw" />
        <span class="sr-only text-xs">Back to {{ backRoute.meta.title }}</span>
      </RouterLink>
    </div>

    <div class="flex items-center">
      <img
        :src="ProfileImage"
        class="rounded-full w-10 h-10"
      >

      <div class="flex flex-col ml-3">
        <span class="text-black font-medium text-base">Amari</span>
        <div class="flex items-center">
          <i class="fa-solid fa-circle text-green-400 !w-2 !h-auto" /> <span class="font-light ml-1.5 text-sm">Online</span>
        </div>
      </div>
    </div>

    <div class="ml-auto hover:cursor-pointer">
      <button
        class="btn btn-link"
        @click="exportMatter"
      >
        Export
        <i class="fa-duotone fa-file-pdf text-xl text-black" />
      </button>
    </div>
  </div>
</template>

<script setup>

import { useRouter } from 'vue-router';

import ProfileImage from '@/assets/images/amari.png';

import { useEmitter } from '@/composables';
import { Events } from '@/const';

const props = defineProps({
  backRouteName: {
    type: String,
    default: null,
  },
  showBackButton: {
    type: Boolean,
    default: true,
  },
});

const emitter = useEmitter();

const backRoute = useRouter().resolve({ name: props.backRouteName });

const exportMatter = () => {
  emitter.emit(Events.matter.export);
};
</script>

<template>
  <ul>
    <li
      v-for="matter in matters"
      :key="matter.id"
      class="w-full"
    >
      <RouterLink
        :to="{ name: 'app_matter_show', params: { id: matter.id } }"
        class="no-underline"
      >
        <div class="flex border-b pt-2.5 pb-3 w-full">
          <!-- Category Speech Bubble -->
          <div
            class="relative rounded-full  flex justify-center items-center"
          >
            <i
              class="fa-duotone fa-messages text-primary mt-[3px] text-3xl"
              :style="getCategoryColorClass(matter.form.category.id).bubble"
            />
            <span
              class="text-sm font-semibold text-white absolute  top-1.5 left-1"
              :style="getCategoryColorClass(matter.form.category.id).text"
            >
              {{ getCategoryInitials(matter.form.category.name) }}
            </span>
          </div>

          <div class="ml-2 text-xs flex-grow">
            <div class="flex items-center justify-between">
              <div>
                <p class="text-sm sm:text-sm mb-0.5">
                  <strong class="text-black">{{ matter.form.name }}</strong>&nbsp;
                </p>
                <span
                  class="text-gray-500"
                >{{ matter.form.category.name }}</span>
              </div>

              <span class="text-xs text-gray-500 font-light">{{ dateFormatter.formatRelative(matter.updatedAt || matter.createdAt) }}</span>
            </div>
          </div>
        </div>
      </RouterLink>
    </li>
  </ul>
</template>

<script setup>
import { useDateFormatter } from '@/composables';

defineProps({
  matters: {
    type: Array,
    default: () => [],
  },
});

const dateFormatter = useDateFormatter();

// the returned colors will be deterministic based on the category ID
// Set up a hash function to generate a consistent hue for each categoryId
const CATEGORY_COLORS_CACHE = {};

function getRandomColor(categoryId) {
  if (CATEGORY_COLORS_CACHE[categoryId]) {
    return CATEGORY_COLORS_CACHE[categoryId];
  }

  let hue;

  do {
    hue = Math.floor(Math.random() * 360);
  } while (hue > 240 && hue < 120);

  let pastel = 'hsl(' + hue + ', 100%, 80%)';
  let dark = 'hsl(' + hue + ', 100%, 20%)';

  CATEGORY_COLORS_CACHE[categoryId] = {
    background: `background-color: ${dark};`,
    text: `color: ${dark};`,
    bubble: `color: ${pastel};`,
  };

  return CATEGORY_COLORS_CACHE[categoryId];
}

const getCategoryColorClass = (categoryId) => getRandomColor(categoryId);

const getCategoryInitials = category => {
  return category.split(' ').slice(0, 2).map(word => word[0].toUpperCase()).join('');
};

</script>

<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div>
        <div class="mb-4">
          <label
            class="sr-only"
            for="email"
          >Email</label>
          <input
            id="email"
            v-model="formData.email"
            class="text-input"
            type="text"
            name="email"
            placeholder="Email"
          >
        </div>

        <div class="mb-4">
          <label
            class="sr-only"
            for="password"
          >Password</label>
          <input
            id="password"
            v-model="formData.password"
            class="text-input"
            type="password"
            name="password"
            placeholder="Password"
          >
        </div>

        <div class="mb-6">
          <RouterLink
            class="text-purple"
            :to="{ name: 'app_security_forgot_password' }"
          >
            Forgot password?
          </RouterLink>
        </div>

        <ButtonContainer>
          <DefaultButton
            :disabled="isSubmitting"
          >
            {{ isSubmitting ? 'Logging in..' : 'Log in' }}
          </DefaultButton>
        </ButtonContainer>

        <div class="text-gray-600 mt-6">
          <p class="text-sm text-center">
            Don't have an account yet?
          </p>
          <DefaultButton
            :to="{ name: 'app_security_sign_up' }"
            button-colour-class="btn-primary-light-blue"
          >
            Sign up
          </DefaultButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
// Package Imports
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';

import { ButtonContainer, DefaultButton } from '@/components/buttons';

import { useHttp, useToast } from '@/composables';
import { HttpStatus } from '@/const';
import { useSecurityStore } from '@/store';

// Composables
const router = useRouter();
const store = useSecurityStore();
const toast = useToast();

// Refs & Reactive Properties && Computed Properties
const formData = reactive({
  email: process.env.NODE_ENV === 'development' ? 'admin@example.com' : null,
  password: process.env.NODE_ENV === 'development' ? 'admin123' : null,
});
const isSubmitting = ref(false);

// Functions
const handleSubmit = async () => {
  isSubmitting.value = true;

  toast.clear();

  const response = await useHttp().post('auth/login', formData);

  if (response.statusCode === HttpStatus.OK) {
    store.user = response.data;
    await router.push({ name: 'app_matter_list' });
  } else {
    toast.error(response.exception);
  }

  isSubmitting.value = false;
};

</script>

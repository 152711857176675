<template>
  <!-- Logo -->
  <div class="mx-auto my-2 w-48">
    <img
      :src="Logo"
      class="w-48 my-5"
    >
  </div>

  <!-- Banner -->
  <div class="pl-8 py-8 bg-primary-light h-40">
    <div class="flex items-center">
      <div class="badge mx-auto">
        <span>
          {{ matter.concluded ? 'Concluded' : 'Active' }}
        </span>
      </div>
    </div>

    <div class="flex flex-col items-center">
      <h2 class="mb-3">
        {{ matter.form.name }}
      </h2>

      <div class="flex mb-6">
        {{ dateFormatter.formatShortDate(matter.createdAt) }}

        <span class="mx-2">|</span>

        {{ name }}
      </div>
    </div>
  </div>

  <!-- Chat messages -->
  <div class="pb-14 w-2/3 max-w-screen-md mx-auto mt-20">
    <div
      v-for="(message, index) in matter.messages"
      :key="index"
    >
      <template v-if="message.role === 'system' || message.role === 'assistant'">
        <div
          class="bg-primary text-white inline-block mb-4 px-3.5 py-2.5 rounded-lg max-w-prose"
          style="page-break-inside: avoid;"
        >
          {{ message.content }}
        </div>
      </template>

      <template v-else-if="message.role === 'user'">
        <div
          class="flex justify-end"
          style="page-break-inside: avoid;"
        >
          <div class="bg-neutral-200 text-black inline-block mb-4 px-3.5 py-2.5 rounded-lg max-w-prose text-right">
            {{ message.content }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import { useDateFormatter } from '@/composables/index.js';
import Logo from '@/assets/images/virtual-lawyer-logo.png';
import '@/assets/styles/main.scss';

const props = defineProps({
  client: {
    type: Object,
    default: null,
  },
  matter: {
    type: Object,
    default: null,
  },
});

const dateFormatter = useDateFormatter();

const name = computed(() => props.client.firstName ? `${props.client.firstName} ${props.client.lastName}` : props.client.username);
</script>

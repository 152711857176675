<template>
  <PageWrapper>
    <AppHeader>
      <MatterChatHeader />
    </AppHeader>

    <AppBody>
      <PageContentLoader
        :is-loading="isLoading"
        :error="error"
        loading-text="Loading matter chat..."
      >
        <div
          ref="chatMessages"
          class="max-w-screen-lg mx-auto pb-48"
        >
          <SystemMessage :animate="false">
            {{ matter.form.welcomeText }}
          </SystemMessage>

          <!-- Content -->
          <div
            v-for="(message, index) in matter.messages"
            :key="index"
          >
            <SystemMessage v-if="message.role === 'system' || message.role === 'assistant'">
              {{ message.content }}
            </SystemMessage>

            <UserMessage v-if="message.role === 'user'">
              {{ message.content }}
            </UserMessage>
          </div>

          <SystemMessage v-if="isReceivingMessage">
            <LoadingMessage />
          </SystemMessage>

          <template v-else>
            <template v-if="currentBlock">
              <!-- Current question -->
              <div>
                <div class="bg-primary rounded-r-lg rounded-bl-lg px-3.5 py-2 text-white mb-1 relative inline-block max-w-screen-xxs lg:max-w-screen-md">
                  <div class="absolute -left-2 -top-1.5">
                    <i class="fa-solid fa-diamond-half text-primary rotate-[135deg]" />
                  </div>
                  <p class="text-white mb-0">
                    {{ currentBlock.title }}
                  </p>
                </div>
              </div>
            </template>
          </template>
        </div>
      </PageContentLoader>
    </AppBody>

    <!-- Chat actions -->
    <div
      class="fixed bg-white border-t shadow-md bottom-0 w-full pl-3.5 pr-2.5 py-2 overflow-scroll"
    >
      <template v-if="currentBlock && !isReceivingMessage">
        <template v-if="currentBlock.type === 'text'">
          <div class="flex items-center space-x-2">
            <input
              v-model="textAnswer"
              v-focus
              type="text"
              class="bg-gray-100 !h-9"
              @keyup.enter="submitText(currentBlock)"
            >
            <button
              class="rounded-full w-8 h-8 relative flex-shrink-0"
              :class="{
                'bg-neutral-500': textAnswer.trim() === '' || isSendingMessage,
                'bg-secondary': textAnswer.trim() !== '' && !isSendingMessage
              }"
              :disabled="textAnswer.trim() === '' || isSendingMessage"
              @click="submitText(currentBlock)"
            >
              <span v-show="isSendingMessage">
                <i class="far fa-circle-notch fa-spin text-white" />
              </span>
              <span v-show="!isSendingMessage">
                <i class="fa-solid fa-paper-plane-top text-white text-sm absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" />
              </span>
            </button>
          </div>
        </template>

        <template v-else-if="currentBlock.type === 'choice'">
          <div class="flex flex-col items-center space-y-2 w-full">
            <button
              v-for="choice in currentBlock.choices"
              :key="choice.uid"
              class="text-sm text-gray-500 border border-gray-300 shadow-sm px-4 py-2 font-medium rounded-full whitespace-nowrap w-full max-w-screen-xxs"
              :class="{'bg-neutral-100': isSendingMessage}"
              :disabled="isSendingMessage"
              @click="submitChoice(currentBlock, choice)"
            >
              {{ choice.text }}
              <span
                v-show="isSendingMessage && choice.text === choiceAnswer"
                class="ml-1"
              >
                <i class="far fa-circle-notch fa-spin" />
              </span>
            </button>
          </div>
        </template>
      </template>
    </div>
  </PageWrapper>
</template>

<script setup>
import html2pdf from 'html2pdf.js';
import { createApp, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { AppHeader, AppBody } from '@/components';
import {
  SystemMessage,
  UserMessage,
  LoadingMessage,
  MatterForExport,
} from '@/components/chat';
import { MatterChatHeader } from '@/components/headers';
import { PageContentLoader } from '@/components/loaders';
import { PageWrapper } from '@/components/wrappers';

import { useEmitterEvent, useHttp } from '@/composables';
import { Events, HttpStatus } from '@/const';
import { DayjsPlugin } from '@/plugins';
import { useSecurityStore } from '@/store';

const store = useSecurityStore();

const isLoading = ref(true);
const isSendingMessage = ref(false);
const isReceivingMessage = ref(false);

const error = ref(null);
const matter = ref(null);
const currentBlock = ref(null);
const textAnswer = ref('');
const choiceAnswer = ref('');

onMounted(async () => {
  await loadMatter();

  if (matter.value.activeBlockUid) {
    currentBlock.value = matter.value.form.blocks.find(block => block.uid === matter.value.activeBlockUid);
  }
  else {
    if (matter.value.concluded) {
      currentBlock.value = null;
    }
    else {
      if (matter.value.messages.length === 0) {
        isReceivingMessage.value = true;
        await new Promise(resolve => setTimeout(resolve, generateRandomNumber(2000, 3000)));
        isReceivingMessage.value = false;
      }
      currentBlock.value = matter.value.form.blocks.find(block => block.type !== 'section');
    }
  }
});

const loadMatter = async () => {
  const matterId = useRoute().params.id;
  const response = await useHttp().get(`/matters/${matterId}`);

  if (response.statusCode === HttpStatus.OK) {
    matter.value = response.data;
  } else {
    error.value = response.exception;
  }

  isLoading.value = false;
};

const calculateCurrentSection = (block) => {
  const index = matter.value.form.blocks.findIndex(item => item.uid === block.uid);
  for (let i=index; i>=0; i--) {
    if (matter.value.form.blocks[i].type === 'section') {
      return matter.value.form.blocks[i];
    }
  }

  return null;
};

const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const submitText = async (block) => {
  await submitAnswer(block, textAnswer.value);
  textAnswer.value = '';
};

const submitChoice = async (block, choice) => {
  choiceAnswer.value = choice.text;
  await submitAnswer(block, choice);
  choiceAnswer.value = '';
};

const submitAnswer = async (block, answer) => {
  isSendingMessage.value = true;
  await new Promise(resolve => setTimeout(resolve, generateRandomNumber(250, 2000)));

  let actionType = null; // Will ultimately be one of: [null, 'nextSection', 'goToSection', 'submit']
  let actionValue = null; // Will ultimately be null, or the section UID to go to, if going to a specific section.

  matter.value.messages.push({
    role: 'system',
    content: block.title,
  });

  matter.value.messages.push({
    role: 'user',
    content: block.type === 'choice' ? answer.text : answer,
  });

  const currentBlockIndex = matter.value.form.blocks.findIndex(item => item.uid === block.uid);

  // If the current block is a choice block that has conditional logic enabled, we use that logic
  if (block.conditionalLogic) {
    actionType = answer.action.type;
    actionValue = answer.action.value;
  }
  // If the block does not have conditional logic, then we need to check if the next block is the last
  // block in the section (or even the last block on the entire form).
  //  - If it is, then we use the 'onComplete' logic of the current section to determine what to do next.
  //  - If it is not, then we simply progress onto the next question
  else {
    // If the current block is the last block in the section (which we determine by checking if the next block is
    // a 'section' block), or if the current block is the last block in the entire form (which we determine by checking
    // if the current index is equal to the length of the entire list of blocks)
    const isLastBlockInForm = (currentBlockIndex === (matter.value.form.blocks.length-1));
    const isLastBlockInSection = isLastBlockInForm? true : (matter.value.form.blocks[currentBlockIndex+1].type === 'section');
    if (isLastBlockInForm || isLastBlockInSection) {
      const currentSection = calculateCurrentSection(block);

      // Since we have reached the end of the section, we use the onComplete logic of the current section
      // to determine what to do next.
      actionType = currentSection.onComplete.action.type;
      actionValue = currentSection.onComplete.action.value ? currentSection.onComplete.action.value : null;

      // If we've reached the last block in the form, but the 'actionType' is set to 'nextSection', then we have
      // encountered a logic flaw, most likely because the form wasn't set up correctly in the admin back-end. To
      // counter this, we force a submit at this point.
      actionType = (actionType === 'nextSection' && isLastBlockInForm) ? 'submit' : actionType;
    }
  }

  // Go to the next section (the one that follows linearly from the current block)
  if (actionType === 'nextSection') {
    // Iterate over all the blocks, starting from the position of the current block, and find the next section block
    for (let i=currentBlockIndex; i<matter.value.form.blocks.length; i++) {
      // We've discovered the next section block
      if (matter.value.form.blocks[i].type === 'section') {
        // We've discovered the next section block, but the actual question is still one step further on (index+1).
        currentBlock.value = matter.value.form.blocks[i+1];
        matter.value.activeBlockUid = currentBlock.value.uid;
        break;
      }
    }
  }
  // Go to a specific section
  else if (actionType === 'goToSection') {
    const destinationSectionBlockIndex = matter.value.form.blocks.findIndex(item => item.uid === actionValue);
    // We've discovered the destination section block, but the actual question is still one step further on (index+1).
    currentBlock.value = matter.value.form.blocks[destinationSectionBlockIndex+1];
    matter.value.activeBlockUid = currentBlock.value.uid;
  }
  // Submit the form
  // TODO
  else if (actionType === 'submit') {
    currentBlock.value = null;
    matter.value.activeBlockUid = null;
    matter.value.concluded = true;
  }
  // Go to the next question
  else {
    currentBlock.value = matter.value.form.blocks[currentBlockIndex+1];
    matter.value.activeBlockUid = currentBlock.value.uid;
  }

  isSendingMessage.value = false;
  isReceivingMessage.value = true;

  const response = await useHttp().put(`/matters/${matter.value.id}`, {
    activeBlockUid: matter.value.activeBlockUid,
    messages: matter.value.messages,
    concluded: matter.value.concluded,
  });

  if (response.data) {
    matter.value.messages = response.data.messages;
  }

  await new Promise(resolve => setTimeout(resolve, generateRandomNumber(250, 2000)));
  isReceivingMessage.value = false;
};

const handleExportMatter = () => {
  const matterComponent = createApp(MatterForExport, { matter: matter.value, client: store.user });
  const matterContainer = document.createElement('div');

  matterComponent
    .use(DayjsPlugin)
    .mount(matterContainer);

  html2pdf(matterContainer.innerHTML, {
    html2canvas: { scale: 2 },
  });
};

useEmitterEvent(Events.matter.export, handleExportMatter);
</script>
